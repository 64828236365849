import React from 'react'
import './Studio.css'

import SingleImage from './../../molecules/SingleImage/SingleImage'
import Text from './../../molecules/Text/Text'
import Gallery from '../../molecules/Gallery/Gallery'
import Attachments from '../../molecules/Attachments/Attachments'

class Studio extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            page: this.props.responses.pages.data.find((o) => { return o.slug === this.props.slug }),
            style: null,
        }
    }

    setPadding = () => {
        this.setState({
            style: {
                paddingTop: `${document.querySelector(`.Header`).offsetHeight}px`
            }
        })
    }

    updateData(props) {
        this.setState({
            page: props.responses.pages.data.find((o) => { return o.slug === props.slug }),
        })
    }

    static getDerivedStateFromProps = (props, state) => {
        if (props.slug !== state.page.slug) {
            window.scrollTo(0, 0)
            return {
                page: props.responses.pages.data.find((o) => { return o.slug === props.slug }),
            };
        }

        return null;
    }

    componentDidMount = () => {
        window.scrollTo(0, 0)
        this.setPadding()
        window.addEventListener(`resize`, this.setPadding)
    }

    render() {
        return (
                <main className={`Studio`} style={this.state.style}>
                    {/* Intro  */}
                    <section className={`intro`}>
                        <div className={`surtitle`}>
                            {/* Page title */}
                            <p className={`_medium _title`}>{this.state.page.acf.page_title}</p>
                        </div>
                    </section>

                    <div className={`contents`}>
                        {
                            this.state.page.acf.contents.length > 0 && (
                                this.state.page.acf.contents.map((content, index) => {
                                    switch (content.acf_fc_layout) {
                                        case `single_image`:
                                            return (<SingleImage content={content} key={index} />)
                                        case `text`:
                                            return (<Text content={content} key={index} />)
                                        case `gallery`:
                                            return (<Gallery content={content} key={index} />)
                                        case `attachments`:
                                            return (<Attachments content={content} key={index} />)
                                        default:
                                            return false
                                      }
                                }
                            ))
                        }
                    </div>
                </main>
        )
    }
}

export default Studio
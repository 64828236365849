import React from "react";
import "./Exhibition.css";
import Image from "./../../atoms/Image/Image";
import { Link } from "@reach/router";
import { getDateDifference, getDateRange } from "./../../../utils/Utils";

class Exhibition extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			info: false,
			artists: this.props.activity.acf.artists,
			title: this.props.activity.title.rendered,
			preview: this.props.activity.acf.preview,
		};
	}

	toggleInfo = () => {
		this.setState({
			info: !this.state.info,
		});
	};

	render() {
		console.log(this.state.preview);

		return (
			<div className={`Exhibition`}>
				<div className={`surtitle`}>
					{/* Duration */}
					<p className={`_medium _title`}>
						{this.props.type === `preview`
							? this.props.activity.acf.event.fallback
							: getDateRange(
									this.props.activity.acf.event.duration.start
										? this.props.activity.acf.event.duration.start
										: this.props.activity.acf.event.opening.date,
									this.props.activity.acf.event.duration.end
										? this.props.activity.acf.event.duration.end
										: this.props.activity.acf.event.opening.date
							  )}
					</p>
					{/* Time difference */}
					<p className={`_medium _title _opaque`}>
						{this.props.type !== `preview`
							? this.props.type === `running`
								? `In corso`
								: this.props.type === `upcoming`
								? getDateDifference(
										this.props.activity.acf.event.opening.date,
										this.props.activity.acf.event.opening.time
								  )
								: getDateDifference(this.props.activity.acf.event.duration.end)
							: `Seguici per maggiori info`}
					</p>
				</div>

				{/* Switch if upcoming or past */}
				{this.props.type === `past` ||
				!!this.state.preview.force_open_exhibition_page ? (
					<Link to={`/exhibition/${this.props.activity.slug}`}>
						<div className={`title`}>
							{this.state.artists.length > 0 &&
								this.state.artists.map((artist, index) => (
									<p className={`_big _title`} key={index}>
										{artist.name}
									</p>
								))}
							<p className={`_big _title _italic`}>{this.state.title}</p>
						</div>
					</Link>
				) : (
					<div className={`title`} onClick={this.toggleInfo}>
						{this.state.artists.length > 0 &&
							this.state.artists.map((artist, index) => (
								<p className={`_big _title`} key={index}>
									{artist.name}
								</p>
							))}
						<p className={`_big _title _italic`}>{this.state.title}</p>
					</div>
				)}

				<div className={`preview m-width`}>
					{this.state.info && (
						<>
							{this.state.preview.image && (
								<div className={`cover`}>
									<Image src={this.state.preview.image} />
								</div>
							)}
							<div
								className={`_base _text`}
								dangerouslySetInnerHTML={{ __html: this.state.preview.body }}
							/>
							<p className={`_base _text`}>
								{this.state.preview.attachments.length > 0 &&
									this.state.preview.attachments.map((attachment, index) => (
										<React.Fragment key={index}>
											<a target={`_blank`} href={attachment.link}>
												{attachment.label}
											</a>
											<br />
										</React.Fragment>
									))}
							</p>
						</>
					)}

					{this.state.preview.image && this.props.type === "past" && (
						<Link to={`/exhibition/${this.props.activity.slug}`}>
							<div className={`cover`}>
								<Image src={this.state.preview.image} />
							</div>
						</Link>
					)}
				</div>
			</div>
		);
	}
}

export default Exhibition;

import React from 'react'

export const Seventh = ( { title, author, countdown } ) => {
    const doubleDig = (num) => `${Math.trunc(num / 10)}${Math.trunc(num % 10)}`;

    return (
        <>
            <span className={ `_title _right` } >{ countdown.minutes }:{ doubleDig( countdown.seconds ) }</span>
            <span className={ `_title _medium` } >
                { title }
            </span>
            <span className={ `_title` } >
                { author }
            </span>
        </>
    )
}
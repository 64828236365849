import React from 'react'

export const Frame = ( { children, className } ) => {
    return (
        <div className={ [ `sprint-frames-frame`, className ].join( ` ` ) } >
            { children }
        </div>
    )
}

export const Frames = ( { children } ) => {
    return (
        <div className={ `sprint-frames` }>
            { children }
        </div>
    )
}
import React from 'react'
import './Gallery.css'
import Image from './../../atoms/Image/Image'

class Gallery extends React.Component {
    render() {
        return (
            <section className={`Gallery m-width`}>
                <p className="_base _title m-width">{this.props.content.title}</p>
                <div className={`slides`}>
                    {
                        this.props.content.slides.map((slide, index) => (
                            <Image src={slide.image} caption={slide.caption} key={index} />        
                        ))
                    }  
                </div>  
            </section>
        )
    }
}

export default Gallery
import React from "react";
import "./Single.css";
import Image from "./../../atoms/Image/Image";
import { getDateDifference, getDateRange } from "./../../../utils/Utils";

import SingleImage from "./../../molecules/SingleImage/SingleImage";
import Text from "./../../molecules/Text/Text";
import Gallery from "../../molecules/Gallery/Gallery";
import Attachments from "../../molecules/Attachments/Attachments";
import Grid from "../../molecules/Grid/Grid";
import Embed from "../../molecules/Embed/Embed";
import Dates from "date-math";

class Single extends React.Component {
	constructor(props) {
		super(props);
		let data = [
			...this.props.responses.activities.past,
			...this.props.responses.activities.running,
			...this.props.responses.activities.upcoming,
			...this.props.responses.activities.preview,
		];
		this.exhibition = data.find((o) => {
			return o.slug === this.props.slug;
		});
		this.state = {
			style: null,
			artists: this.exhibition.acf.artists,
			title: this.exhibition.title.rendered,
			preview: this.exhibition.acf.preview,
		};
	}

	setPadding = () => {
		this.setState({
			style: {
				paddingTop: `${document.querySelector(`.Header`).offsetHeight}px`,
			},
		});
	};

	componentDidMount = () => {
		window.scrollTo(0, 0);
		this.setPadding();
		window.addEventListener(`resize`, this.setPadding);
	};

	render() {
		const useFallback =
			!(
				this.exhibition.acf.event.duration.start ||
				this.exhibition.acf.event.opening.date
			) &&
			!(
				this.exhibition.acf.event.duration.end ||
				this.exhibition.acf.event.opening.date
			);

		const now = Dates.day.floor(new Date());

		const state = this.exhibition.acf.event.duration.start
			? Dates.day.diff(
					now,
					Dates.day.ceil(new Date(this.exhibition.acf.event.duration.end))
			  ) >= 0
				? Dates.day.diff(
						now,
						Dates.day.ceil(new Date(this.exhibition.acf.event.duration.start))
				  ) < 0
					? "running"
					: "upcoming"
				: "past"
			: "preview";

		return (
			<main className={`Single`}>
				{/* Intro  */}
				<section className={`intro`} style={this.state.style}>
					<div className={`surtitle`}>
						{/* Duration */}
						<p className={`_medium _title`}>
							{useFallback
								? this.exhibition.acf.event.fallback
								: getDateRange(
										this.exhibition.acf.event.duration.start
											? this.exhibition.acf.event.duration.start
											: this.exhibition.acf.event.opening.date,
										this.exhibition.acf.event.duration.end
											? this.exhibition.acf.event.duration.end
											: this.exhibition.acf.event.opening.date
								  )}
						</p>

						{/* Time difference */}
						<p className={`_medium _title _opaque`}>
							{state !== `preview`
								? state === `running`
									? `In corso`
									: state === `upcoming`
									? getDateDifference(
											this.exhibition.acf.event.opening.date,
											this.exhibition.acf.event.opening.time
									  )
									: getDateDifference(this.exhibition.acf.event.duration.end)
								: `Seguici per maggiori info`}
						</p>
					</div>

					<div className={`title`}>
						{this.state.artists.length > 0 &&
							this.state.artists.map((artist, index) => (
								<p className={`_big _title`} key={index}>
									{artist.name}
								</p>
							))}
						<p className={`_big _title _italic`}>{this.state.title}</p>
					</div>

					{this.exhibition.acf.cover.image && (
						<div className={`cover`}>
							<Image
								src={this.exhibition.acf.cover.image}
								caption={this.exhibition.acf.cover.caption}
							/>
						</div>
					)}
				</section>

				<div className={`contents`}>
					{this.exhibition.acf.contents.length > 0 &&
						this.exhibition.acf.contents.map((content, index) => {
							switch (content.acf_fc_layout) {
								case `single_image`:
									return <SingleImage content={content} key={index} />;
								case `text`:
									return <Text content={content} key={index} />;
								case `gallery`:
									return <Gallery content={content} key={index} />;
								case `attachments`:
									return <Attachments content={content} key={index} />;
								case `grid`:
									return <Grid content={content} key={index} />;
								case `embed`:
									return <Embed content={content} key={index} />;
								default:
									return false;
							}
						})}
				</div>
			</main>
		);
	}
}

export default Single;

import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { Router } from "@reach/router";
import axios from "axios";
import Dates from "date-math";

import Video from "./utils/assets/video/Clouds.mp4";

import "./utils/reset.css";
import "./utils/common.css";
import "./utils/typography.css";
import "./utils/variables.css";
import Home from "./views/templates/Home/Home";
import Single from "./views/templates/Single/Single";
import Studio from "./views/templates/Studio/Studio";
import Newsletter from "./views/templates/Newsletter/Newsletter";
import Header from "./views/molecules/Header/Header";
import Footer from "./views/molecules/Footer/Footer";

import { Special } from "./views/specials";

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			responses: {
				activities: null,
				pages: null,
			},
		};
	}

	componentDidMount() {
		axios
			.get(`https://admin.bracebrace.space/wp-json/wp/v2/pages`, {
				headers: {
					"content-type": "application/json",
				},
			})
			.then((response) => {
				this.setState({
					responses: { ...this.state.responses, pages: response },
				});
			});

		axios
			.get(`https://admin.bracebrace.space/wp-json/wp/v2/activity`, {
				headers: {
					"content-type": "application/json",
				},
			})
			.then((response) => {
				var now = Dates.day.floor(new Date());

				let activities = {
					preview: [],
					upcoming: [],
					running: [],
					past: [],
				};

				// Fare una fix per differenziare giorno dell'opening e inizio mostra
				response.data.map((activity, index) => {
					activity.acf.event.duration.start
						? Dates.day.diff(
								now,
								Dates.day.ceil(new Date(activity.acf.event.duration.end))
						  ) >= 0
							? Dates.day.diff(
									now,
									Dates.day.ceil(new Date(activity.acf.event.duration.start))
							  ) < 0
								? // ? Dates.day.diff(now, Dates.day.ceil(new Date(activity.acf.event.duration.start))) <= 0
								  activities.running.push(activity)
								: activities.upcoming.push(activity)
							: activities.past.push(activity)
						: activities.preview.push(activity);

					return false;
				});

				activities.upcoming = activities.upcoming.sort((a, b) =>
					a.acf.event.opening.date > b.acf.event.opening.date ? 1 : -1
				);
				activities.past = activities.past.sort((a, b) =>
					a.acf.event.opening.date < b.acf.event.opening.date ? 1 : -1
				);

				this.setState({
					responses: { ...this.state.responses, activities: activities },
				});
			});
	}

	componentDidUpdate() {
		console.log(this.state.responses);
	}

	render() {
		return (
			<React.Fragment>
				{this.state.responses.activities && this.state.responses.pages ? (
					<>
						<div id='background'>
							<video autoPlay muted playsInline loop id='myVideo'>
								<source src={Video} type='video/mp4'></source>
							</video>
						</div>

						<Header />

						<Router component={React.Fragment} primary={false}>
							<Home path='/' responses={this.state.responses} />
							<Single
								path='/exhibition/:slug'
								responses={this.state.responses}
							/>
							<Newsletter path='/newsletter' />
							<Studio path='/:slug' responses={this.state.responses} />
							<Special path='/sprint' />
						</Router>

						<Footer />

						<Header position={`bottom`} />
					</>
				) : (
					<></>
				)}
			</React.Fragment>
		);
	}
}

const sprintToken = localStorage.getItem("sprintToken");
// const sprintToken = true;

ReactDOM.render(
	<React.StrictMode>
		{/* { sprintToken ? <App /> : <Special /> }  */}
		<App />
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

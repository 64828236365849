import React from 'react'

export const Fifth = ( { countdown } ) => {
    const doubleDig = (num) => `${Math.trunc(num / 10)}${Math.trunc(num % 10)}`;

    return (
        <>
            <span className={ `_title _big _center` } >{ countdown.minutes }:{ doubleDig( countdown.seconds ) }</span>
        </>
    )
}
import React from 'react'
import convert from 'convert-seconds'

import './sprint.scss'

import { Heading } from './Heading'
import { Frames, Frame } from './Frames'

import { First } from './frames/first'
import { Second } from './frames/second'
import { Third } from './frames/third'
import { Fifth } from './frames/fifth'
import { Seventh } from './frames/seventh'
import { Ninth } from './frames/ninth'
import { Tenth } from './frames/tenth'

import rondini from './assets/rondini.mp4'
import guildor from './assets/guildor.mp4'
import rondiniDesktop from './assets/rondini_desktop.mp4'
import guildorDesktop from './assets/guildor_desktop.mp4'

import rondiniDesktopPoster from './assets/rondini_desktop-poster.jpg'
import rondiniMobilePoster from './assets/rondini_mobile-poster.jpg'
import guildorDesktopPoster from './assets/guildor_desktop-poster.jpg'
import guildorMobilePoster from './assets/guildor_mobile-poster.jpg'

export const Sprint = () => {
    const [ timer, setTimer ] = React.useState( null )
    const timerRef = React.useRef( timer )

    const currentPath = React.useRef( Math.round( Math.random() ) )

    const [ height, setHeight ] = React.useState( window.innerHeight )
    
    const [ countdown, setCountDown ] = React.useState( 225 - timer )

    const interval = React.useRef( null )

    const startTimer = () => {
        updateTime( 0 )
        interval.current = setInterval( () => { 
            timerRef.current++
            updateTime( timerRef.current )
        }, 1000 )
    }

    const updateTime = ( time ) => {
        timerRef.current = time
        setTimer( time )
        setCountDown( 225 - time )
    }

    const pauseTimer = () => {
        clearInterval( interval.current )
    }

    const resumeTimer = () => {
        interval.current = setInterval( () => { 
            timerRef.current++
            updateTime( timerRef.current )
        }, 1000 )
    }

    const onResize = () => {
        window.matchMedia('screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape)').matches
            ? setHeight( window.innerWidth )
            : setHeight( window.innerHeight )
    }

    React.useEffect( () => {
        window.addEventListener( 'resize', onResize )

        return () => {
            window.removeEventListener( 'resize', onResize )
        }
    }, [] )

    return (
        <div className={ [ `sprint` ].join( ` ` ) } style={ { height: height } } >

            <style dangerouslySetInnerHTML={{__html: `
                html { overflow: hidden }
                #root > header.Header { display: none; }
            `}} />

            { ( timer < 10 || timer > 224 ) && <Heading position={ `top` } /> }
            <Frames>

                { 
                currentPath.current === 0 && (
                    <>
                        { timer == null && <Frame className={ `first centered` } ><First onClick={ () => startTimer() } /></Frame> }
                        { timer != null && timer >= 0 && timer < 2 && <Frame className={ `second centered` } ><Second /></Frame> }
                        { timer >= 2 && timer < 10 && <Frame className={ `third centered` } ><Third /></Frame> }
                        { timer >= 10 && timer < 11 && <Frame className={ `fourth` } ></Frame> }
                        { timer >= 11 && timer < 14 && <Frame className={ `fifth centered` } ><Fifth countdown={ convert(countdown) } /></Frame> } 
                        {/* { timer >= 15 && timer < 16 && <Frame className={ `sixth` } ><Sixth countdown={ convert(countdown) } /></Frame> } */}
                        { timer >= 15 && timer < 19 && <Frame className={ `seventh` } ><Seventh 
                            title={ `This political statement is a suggestion on how political statements should now be` }
                            author={ `Guildor` }
                            countdown={ convert(countdown) } /></Frame> }
                        { timer >= 19 && timer < 20 && <Frame className={ `fourth` } ></Frame> }
                        { timer >= 20 && timer < 62 && <Frame className={ `ninth` } ><Ninth 
                            srcMobile={ guildor } 
                            mobilePoster={ guildorMobilePoster } 
                            pauseTimer={ pauseTimer } 
                            srcDesktop={ guildorDesktop } 
                            desktopPoster={ guildorDesktopPoster } 
                            onVideoEnd={ () => {
                                updateTime( 62 )
                                resumeTimer()
                            } } /></Frame> }
                        
                        { timer >= 62 && timer < 64 && <Frame className={ `fourth` } ></Frame> }
                        { timer >= 64 && timer < 67 && <Frame className={ `fifth centered` } ><Fifth countdown={ convert(countdown) } /></Frame> }
                        {/* { timer >= 66 && timer < 68 && <Frame className={ `sixth` } ><Sixth countdown={ convert(countdown) } /></Frame> } */}
                        { timer >= 67 && timer < 71 && <Frame className={ `seventh` } ><Seventh 
                            title={ `Rondini` }
                            author={ `Ginevra Dolcemare` }
                            countdown={ convert(countdown) } /></Frame> }
                        { timer >= 71 && timer < 72 && <Frame className={ `fourth` } ></Frame> }
                        { timer >= 72 && timer < 218 && <Frame className={ `ninth` } ><Ninth 
                            pauseTimer={ pauseTimer } 
                            srcMobile={ rondini } 
                            mobilePoster={ rondiniMobilePoster } 
                            srcDesktop={ rondiniDesktop } 
                            desktopPoster={ rondiniDesktopPoster } 
                            onVideoEnd={ () => {
                                updateTime( 218 )
                                resumeTimer()
                            } } /></Frame> }
                        { timer >= 218 && timer < 220 && <Frame className={ `fourth` } ></Frame> }
                        { timer >= 220 && timer < 223 && <Frame className={ `fifth centered` } ><Fifth countdown={ convert(0) } /></Frame> }
                        { timer >= 223 && timer < 224 && <Frame className={ `fourth` } ></Frame> }
                        { timer >= 224 && <Frame className={ `tenth centered` } ><Tenth /></Frame> }
                    </>
                ) }{
                currentPath.current === 1 && (
                    <>
            { timer == null && <Frame className={ `first centered` } ><First onClick={ () => startTimer() } /></Frame> }
            { timer != null && timer >= 0 && timer < 2 && <Frame className={ `second centered` } ><Second /></Frame> }
            { timer >= 2 && timer < 10 && <Frame className={ `third centered` } ><Third /></Frame> }
            { timer >= 10 && timer < 11 && <Frame className={ `fourth` } ></Frame> }
            { timer >= 11 && timer < 14 && <Frame className={ `fifth centered` } ><Fifth countdown={ convert(countdown) } /></Frame> } 
            {/* { timer >= 15 && timer < 16 && <Frame className={ `sixth` } ><Sixth countdown={ convert(countdown) } /></Frame> } */}
            { timer >= 15 && timer < 19 && <Frame className={ `seventh` } ><Seventh 
                title={ `Rondini` }
                author={ `Ginevra Dolcemare` }
                countdown={ convert(countdown) } /></Frame> }
            { timer >= 19 && timer < 20 && <Frame className={ `fourth` } ></Frame> }
            { timer >= 20 && timer < 166 && <Frame className={ `ninth` } ><Ninth 
                pauseTimer={ pauseTimer } 
                srcMobile={ rondini } 
                mobilePoster={ rondiniMobilePoster } 
                srcDesktop={ rondiniDesktop } 
                desktopPoster={ rondiniDesktopPoster } 
                onVideoEnd={ () => {
                    updateTime( 166 )
                    resumeTimer()
                } } /></Frame> }
            
            { timer >= 166 && timer < 168 && <Frame className={ `fourth` } ></Frame> }
            { timer >= 168 && timer < 171 && <Frame className={ `fifth centered` } ><Fifth countdown={ convert(countdown) } /></Frame> }
            {/* { timer >= 66 && timer < 68 && <Frame className={ `sixth` } ><Sixth countdown={ convert(countdown) } /></Frame> } */}
            { timer >= 171 && timer < 173 && <Frame className={ `seventh` } ><Seventh 
                title={ `This political statement is a suggestion on how political statements should now be` }
                author={ `Guildor` }
                countdown={ convert(countdown) } /></Frame> }
            { timer >= 173 && timer < 174 && <Frame className={ `fourth` } ></Frame> }
            { timer >= 174 && timer < 218 && <Frame className={ `ninth` } ><Ninth 
                pauseTimer={ pauseTimer } 
                srcMobile={ guildor } 
                mobilePoster={ guildorMobilePoster } 
                srcDesktop={ guildorDesktop } 
                desktopPoster={ guildorDesktopPoster } 
                onVideoEnd={ () => {
                    updateTime( 218 )
                    resumeTimer()
                } } /></Frame> }


            { timer >= 218 && timer < 220 && <Frame className={ `fourth` } ></Frame> }
            { timer >= 220 && timer < 223 && <Frame className={ `fifth centered` } ><Fifth countdown={ convert(0) } /></Frame> }
            { timer >= 223 && timer < 224 && <Frame className={ `fourth` } ></Frame> }
            { timer >= 224 && <Frame className={ `tenth centered` } ><Tenth /></Frame> }
        </>
                )
                }
            
        
            </Frames>
            { ( timer < 10 || timer > 224 ) && <Heading position={ `bottom` } /> }
        </div>
    )
}
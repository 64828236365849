import React from 'react'
import * as THREE from 'three';

import scene_json from './../assets/scene.json'

let scene, camera, renderer;

scene = new THREE.ObjectLoader().parse( scene_json );
camera = new THREE.PerspectiveCamera( 40, window.innerWidth / window.innerHeight ,1 , 5000);
renderer = new THREE.WebGLRenderer( { antialias:true, alpha: true } );

camera.position.z = window.matchMedia('(min-width: 768px)').matches
    ? 1.5
    : 3

export const Tenth = () => {

    const container = React.useRef( null )

    camera.lookAt( 0,0,0 )

    // renderer.setClearColor( 0x000000, 0 );

    renderer.setSize( window.innerWidth, window.innerHeight );
    
    const init = () => {
        container.current.appendChild( renderer.domElement );
        render()
    }

    const render = () => {
        scene.children[0].rotateX( 0.01 )
        scene.children[0].rotateY( 0.01 )
        requestAnimationFrame( render );
        renderer.render( scene, camera );
    }

    const handleResize = () => {

        camera.position.z = window.matchMedia('(min-width: 768px)').matches
            ? 1.5
            : 3

        camera.aspect = window.innerWidth / window.innerHeight
        camera.updateProjectionMatrix()
        renderer.setSize(window.innerWidth, window.innerHeight)
    }

    React.useEffect( () => {
        init()

        window.addEventListener('resize', handleResize, false)
        return () => {
            window.removeEventListener('resize', handleResize, false)
        }
    }, [] )

    const onClick = async () => {
        localStorage.setItem('sprintToken', true)
        setTimeout( ()=> { window.location.href = "/" }, 1000 )
    }

    return (
        <>
            <div ref={ container } />
            <span className={ `_title _big _center` } >A Political Statement</span>
            <span className={ `_title _medium _center` } >per SPRINT 2020</span>
            <a rel="noopener noreferrer" href={`/docs/APoliticalStatement-BRACEBRACE.pdf`} download className={ `closer` } onClick={ onClick } />
        </>
    )
}
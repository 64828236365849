import React from "react";
import Lottie from "react-lottie";
import animationData from "./../assets/countdown-1.json";

export const Third = () => {
	const sentences = [
		`Stop to do what you are doing.`,
		`Wear your headphones.`,
		`Shut up.`,
		`Watch carefully what’s next.`,
	];

	const [round, setRound] = React.useState(0);

	let pointer = React.useRef(0);

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};

	const updateRound = (value) => {
		setRound(value);
	};

	return (
		<>
			<div className={`progress`} style={{ opacity: 1 - round * 0.25 }}>
				<div className={`animation`}>
					<Lottie
						options={defaultOptions}
						isClickToPauseDisabled={true}
						eventListeners={[
							{
								eventName: "loopComplete",
								callback: () => {
									pointer.current++;
									updateRound(pointer.current);
								},
							},
						]}
					/>
				</div>
				<span className={`_title _biggest _center`}>5</span>
			</div>
			<span className={`_title _big _center`} style={{ padding: 10 }}>
				{sentences[round]}
			</span>
		</>
	);
};

import React from 'react';
import { Link } from "@reach/router"
import "./Header.css"

import B from "./assets/imgs/B.svg"
import R from "./assets/imgs/R.svg"
import A from "./assets/imgs/A.svg"
import C from "./assets/imgs/C.svg"
import E from "./assets/imgs/E.svg"


class Header extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            open: false
        }
    }

    toggleOpen = () => {
        this.setState({
            open: !this.state.open
        })
    }

    closeMenu = () => {
        this.setState({
            open: false
        })
    }

    componentDidMount = () => {
        window.addEventListener(`scroll`, this.closeMenu)
    }

    render() {
        return(
            <>
                <header className={`Header ${this.props.position ? this.props.position : `top`} ${this.state.open ? `is-open` : `not-open`}`} onClick={this.toggleOpen}>
                    <nav className={`Menu`}>
                        <ul>
                            <li className={`_medium _title`}><Link to="/">Home</Link></li>
                            <li className={`_medium _title`}><Link to="/studio">Studio</Link></li>
                            <li className={`_medium _title`}><Link to="/edizioni">Edizioni</Link></li>
                        </ul>
                    </nav>
                    <div className={`Logo _medium _title`}>
                        <span data-letter={`B`}>
                            <img className={`_medium _title`} src={B} alt={`Letter B`}></img>
                        </span>
                        <span data-letter={`R`}>
                            <img className={`_medium _title`} src={R} alt={`Letter R`}></img>
                        </span>
                        <span data-letter={`A`}>
                            <img className={`_medium _title`} src={A} alt={`Letter A`}></img>
                        </span>
                        <span data-letter={`C`}>
                            <img className={`_medium _title`} src={C} alt={`Letter C`}></img>
                        </span>
                        <span data-letter={`E`}>
                            <img className={`_medium _title`} src={E} alt={`Letter E`}></img>
                        </span>
                    </div>
                </header>
            </>
            
        )
    }
}

export default Header
import Dates from "date-math";

const getDateDifference = (date, time = null) => {
	let formatted = null;

	function SplitTime(numberOfHours) {
		var Days = Math.floor(numberOfHours / 24);
		var Remainder = numberOfHours % 24;
		var Hours = Math.floor(Remainder);
		var Minutes = Math.floor(60 * (Remainder - Hours));
		return { Days: Days, Hours: Hours, Minutes: Minutes };
	}

	var now = Dates.day.floor(new Date());
	var then = Dates.day.ceil(new Date(date));
	var diff = Dates.hour.diff(now, then);

	var amounts = SplitTime(Math.abs(diff));

	diff >= 0
		? (formatted = `Opening ${
				amounts.Days > 0
					? amounts.Days > 1
						? `tra ${amounts.Days} giorni`
						: `tra ${amounts.Days} giorno`
					: `oggi`
		  } alle ${time}`)
		: (formatted = `Chiusa 
            ${
							Dates.month.diff(then, now) > 0
								? Dates.month.diff(then, now) > 1
									? `${Dates.month.diff(then, now)} mesi`
									: `${Dates.month.diff(then, now)} mese`
								: Dates.day.diff(then, now) > 1
								? `${Dates.day.diff(then, now)} giorni`
								: `${Dates.day.diff(then, now)} giorno`
						} fa`);

	return formatted;
};

const getDateRange = (first, second) => {
	let formatted = null;
	let months = [
		`Gen`,
		`Feb`,
		`Mar`,
		`Apr`,
		`Mag`,
		`Giu`,
		`Lug`,
		`Ago`,
		`Set`,
		`Ott`,
		`Nov`,
		`Dic`,
	];

	let start = {};
	start.date = new Date(first);
	start.month = start.date.getMonth();
	start.day = start.date.getDate();
	start.year = start.date.getFullYear();

	let end = {};
	end.date = new Date(second);
	end.month = end.date.getMonth();
	end.day = end.date.getDate();
	end.year = end.date.getFullYear();

	start.year === end.year
		? start.month === end.month
			? start.day === end.day
				? (formatted = `${start.day} ${months[start.month]} ${start.year}`)
				: (formatted = `${start.day} — ${end.day} ${months[start.month]} ${
						start.year
				  }`)
			: (formatted = `${start.day} ${months[start.month]} - ${end.day} ${
					months[end.month]
			  } ${start.year}`)
		: (formatted = `${start.day} ${months[start.month]} ${start.year} — ${
				end.day
		  }.${months[end.month]} ${end.year}`);

	return formatted;
};

export { getDateDifference, getDateRange };

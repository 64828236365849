import React from "react";
import "./Home.css";
import Hero from "./../../organisms/Hero/Hero";
import clipart from "./assets/imgs/clouds-clipart.svg";
import Image from "./../../atoms/Image/Image";
import Exhibition from "./../../molecules/Exhibition/Exhibition";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.page = this.props.responses.pages.data.find((o) => {
      return o.slug === "home";
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <div className={`modal`}>
          <Image src={{ sizes: { large: clipart } }} />
        </div>

        <Hero>
          <div className={`News`}>
            <span className={`_medium _title`}>{this.page.acf.surtitle}</span>
            <div
              className={`_big _title`}
              dangerouslySetInnerHTML={{ __html: this.page.acf.title }}
            />
          </div>

          <div className={`Info`}>
            <span className={`column-1 _base _title`}>
              <a
                target={`_blank`}
                href="https://www.google.it/maps/dir//BRACE+BRACE,+Via+Termopili,+36,+20127+Milano+MI/@45.4873158,9.179513,14z/data=!4m16!1m6!3m5!1s0x4786c75924ee25c5:0x2659c6f7e14fc3a5!2sBRACE+BRACE!8m2!3d45.4933054!4d9.220287!4m8!1m0!1m5!1m1!1s0x4786c75924ee25c5:0x2659c6f7e14fc3a5!2m2!1d9.220287!2d45.4933054!3e3"
              >
                BRACE BRACE
                <br />
                Milano
              </a>
            </span>
            <span className={`column-2 _base _title _right`}>
              {/* <a href='tel:0039 349 295 7934'>T +39 349 295 7934</a> */}
              {/* <br /> */}
              <a href="mailto:hey@bracebrace.space">hey@bracebrace.space</a>
              <br />
              <a
                target={`_blank`}
                href="https://www.instagram.com/bracebrace.space/"
              >
                Instagram
              </a>{" "}
              <a
                target={`_blank`}
                href="https://www.facebook.com/bracebrace.space"
              >
                Facebook
              </a>
            </span>
          </div>
        </Hero>

        <main className="Home">
          {/* Running */}
          {this.props.responses.activities.running.length > 0 && (
            <section className={`running`}>
              {this.props.responses.activities.running.map(
                (activity, index) => (
                  <Exhibition
                    key={index}
                    activity={activity}
                    type={`running`}
                  />
                )
              )}
            </section>
          )}

          {/* Preview */}
          {!!this.props.responses.activities.preview.length && (
            <section className={`preview`}>
              {this.props.responses.activities.preview.map(
                (activity, index) => (
                  <Exhibition
                    key={index}
                    activity={activity}
                    type={`preview`}
                  />
                )
              )}
            </section>
          )}

          {/* Upcoming */}
          {!!this.props.responses.activities.upcoming.length && (
            <section className={`upcoming`}>
              {this.props.responses.activities.upcoming.map(
                (activity, index) => (
                  <Exhibition
                    key={index}
                    activity={activity}
                    type={`upcoming`}
                  />
                )
              )}
            </section>
          )}

          {/* Past */}
          {this.props.responses.activities.past.length > 0 && (
            <section className={`past`}>
              {this.props.responses.activities.past.map((activity, index) => (
                <Exhibition key={index} activity={activity} type={`past`} />
              ))}
            </section>
          )}
        </main>
      </>
    );
  }
}

export default Home;

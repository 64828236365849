import React from 'react'

class Text extends React.Component {
    render() {
        return (
            <section className={`Text  m-width`}>
                {
                    this.props.content.title && (
                        <p className="_base _title">{this.props.content.title}</p>
                    )
                }
                {
                    this.props.content.body && (
                        <div className="_base _text" dangerouslySetInnerHTML={{__html: this.props.content.body}} />
                    )
                }
            </section>
        )
    }
}

export default Text
import React from 'react'

import iconUnmute from './../assets/icon-unmute.svg'
import iconPlay from './../assets/icon-play.svg'

export const Ninth = ( { srcMobile, srcDesktop, mobilePoster, desktopPoster, pauseTimer, onVideoEnd } ) => {
    const mobileVideoElement = React.useRef( null )
    const desktopVideoElement = React.useRef( null )
    const glass = React.useRef( null )

    const [ muteFallback, setMuteFallback ] = React.useState( true )
    const [ playFallback, setPlayFallback ] = React.useState( false )

    const [ isDesktop, setIsDesktop ] = React.useState( window.matchMedia('(min-width: 768px)').matches )

    const updateWidth = () => {
        setIsDesktop( window.matchMedia('(min-width: 768px)').matches )
    }

    React.useEffect( () => {
        pauseTimer()

        window.addEventListener( 'resize', updateWidth )

        return () => {
            window.removeEventListener( 'resize', updateWidth )
        }
    }, [] )

    React.useEffect( () => {
        if( isDesktop ){
            desktopVideoElement.current.currentTime = mobileVideoElement.current.currentTime
            mobileVideoElement.current.pause()
            let promise = desktopVideoElement.current.play()

            if (promise !== undefined) {
                promise.then(_ => {
                  setMuteFallback( false )
                }).catch(error => {
                  setMuteFallback( true )
                  desktopVideoElement.current.muted = true
                  let second = desktopVideoElement.current.play()

                    if( second !== undefined ) {
                    second.then(_ => {
                        setPlayFallback( false )
                    }).catch(error => {
                        setPlayFallback( true )
                    })

                  }
                });
            }

            mobileVideoElement.current.muted = true   

            // desktopVideoElement.current.muted = false    
        } else {
            mobileVideoElement.current.currentTime = desktopVideoElement.current.currentTime
            desktopVideoElement.current.pause()
            let promise = mobileVideoElement.current.play()

            if (promise !== undefined) {
                promise.then(_ => {
                  setMuteFallback( false )
                }).catch(error => {
                  setMuteFallback( true )
                  mobileVideoElement.current.muted = true
                  let second = mobileVideoElement.current.play()

                    if( second !== undefined ) {
                    second.then(_ => {
                        setPlayFallback( false )
                    }).catch(error => {
                        setPlayFallback( true )
                    })

                  }
                });
            }

            desktopVideoElement.current.muted = true 

            // mobileVideoElement.current.muted = false 
        }
    }, [ isDesktop ] )

    const handleVideoClick = ( videoElement ) => {
        if(videoElement.current.muted){
            videoElement.current.muted = false 
        }  
    }

    const onEnded = () => {
        onVideoEnd()
    }

    const onSuspend = ( event ) => {
        setPlayFallback( true )
    }

    return (
        <>
            {/* Mobile */}
            <video 
                ref={ mobileVideoElement } 
                nocontrols="true" 
                playsInline 
                poster={ mobilePoster } 
                posterResizeMode={'cover'}
                style={ { display: isDesktop ? `none` : `block` } }
                onClick={ event => { event.target.play(); setPlayFallback( false ) } }
                onEnded={ onEnded }
            >
                <source src={ srcMobile } type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            {/* Desktop */}
            <video
            posterResizeMode={'cover'} 
                ref={ desktopVideoElement } 
                nocontrols="true" 
                playsInline 
                poster={ desktopPoster } 
                posterResizeMode={'cover'}
                style={ { display: !isDesktop ? `none` : `block` } } 
                onClick={ event => { event.target.play(); setPlayFallback( false ) } }
                onEnded={ onEnded }
            >
                <source src={ srcDesktop } type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <div ref={ glass } className={ `glass` } onClick={ () => { 
                    mobileVideoElement.current.muted = false; 
                    desktopVideoElement.current.muted = false; 

                    isDesktop
                        ? desktopVideoElement.current.click()
                        : mobileVideoElement.current.click()

                    setMuteFallback( false ) 
                } } >
                { playFallback && <img className={ `glass-play` } src={ iconPlay } /> }
                { muteFallback && <img className={ `glass-mute` } src={ iconUnmute } /> }
            </div>
        </>
    )
}
import React from 'react'

import B from "./../../molecules/Header/assets/imgs/B.svg"
import R from "./../../molecules/Header/assets/imgs/R.svg"
import A from "./../../molecules/Header/assets/imgs/A.svg"
import C from "./../../molecules/Header/assets/imgs/C.svg"
import E from "./../../molecules/Header/assets/imgs/E.svg"

export const Heading = ( { position } ) => {
    return (
        <div className={ [ `sprint-heading _medium _title`, `sprint-heading--${ position }` ].join( ` ` ) } >
            <span data-letter={`B`}>
                <img className={`_medium _title`} src={B} alt={`Letter B`}></img>
            </span>
            <span data-letter={`R`}>
                <img className={`_medium _title`} src={R} alt={`Letter R`}></img>
            </span>
            <span data-letter={`A`}>
                <img className={`_medium _title`} src={A} alt={`Letter A`}></img>
            </span>
            <span data-letter={`C`}>
                <img className={`_medium _title`} src={C} alt={`Letter C`}></img>
            </span>
            <span data-letter={`E`}>
                <img className={`_medium _title`} src={E} alt={`Letter E`}></img>
            </span>
        </div>
    )
}
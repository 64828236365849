import React from "react";
import "./Footer.css";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      style: null,
    };
  }

  setPadding = () => {
    this.setState({
      style: {
        paddingBottom: `${document.querySelector(`.Header`).offsetHeight}px`,
      },
    });
  };

  componentDidMount = () => {
    this.setPadding();
    window.addEventListener(`resize`, this.setPadding);
  };

  render() {
    return (
      <footer className={`Footer`} style={this.state.style}>
        <div className={`column-1`}>
          <span className={`_medium _title`}>
            <a
              target={`_blank`}
              href="https://www.google.it/maps/dir//BRACE+BRACE,+Via+Termopili,+36,+20127+Milano+MI/@45.4873158,9.179513,14z/data=!4m16!1m6!3m5!1s0x4786c75924ee25c5:0x2659c6f7e14fc3a5!2sBRACE+BRACE!8m2!3d45.4933054!4d9.220287!4m8!1m0!1m5!1m1!1s0x4786c75924ee25c5:0x2659c6f7e14fc3a5!2m2!1d9.220287!2d45.4933054!3e3"
            >
              BRACE BRACE
              <br />
              Milano
            </a>
          </span>
        </div>
        <div className={`column-2`}>
          <span className={`_medium _title`}>
            Campo aperto che indipendente brucia.
            <br />
            <a target="_blank" href="https://mailchi.mp/2be527aad14c/signup">
              → Iscriviti alla newsletter.
            </a>
          </span>
          <span className={`_medium _title`}>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.instagram.com/bracebrace.space/"
            >
              IG
            </a>{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.facebook.com/bracebrace.space"
            >
              FB
            </a>
            {/* <a href='tel:0039 349 295 7934'>T +39 349 295 7934</a> */}
            {/* <br /> */}
            <a href="mailto:hey@bracebrace.space">hey@bracebrace.space</a>
            <br />
            Design and dev:{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://hund.studio"
            >
              hund.studio
            </a>
          </span>
        </div>
      </footer>
    );
  }
}

export default Footer;

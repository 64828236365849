import React from 'react'
import './Hero.css'

class Hero extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            style: null,
        }
    }

    setPadding = () => {
        this.setState({
            style: {
                padding: `${document.querySelector(`.Header`).offsetHeight}px 0`
            }
        })
    }

    componentDidMount = () => {
        this.setPadding()
        window.addEventListener(`resize`, this.setPadding)
    }

    render() {
        return(
            <section className={`Hero`} style={this.state.style}>
                {this.props.children}
            </section>
        )
    }
}

export default Hero
import React from "react";
import h2p from "html2plaintext";

import "./Grid.css";

export default ({ content }) => {
  return (
    <section className="Grid">
      <p
        className="_base _title m-width"
        dangerouslySetInnerHTML={{ __html: content.title }}
      />
      <div className={`elements elements--${content.columns}`}>
        {content.images &&
          content.images.length &&
          content.images.map((image, index) => (
            <div className="element">
              <img src={image?.image?.sizes?.large} alt={h2p(image?.caption)} />
              <div
                className="_small caption"
                dangerouslySetInnerHTML={{
                  __html: image?.caption,
                }}
              />
            </div>
          ))}
      </div>
    </section>
  );
};

import React from 'react'
import Image from './../../atoms/Image/Image'

class SingleImage extends React.Component {
    render() {
        return (
            <section className={`SingleImage m-width`}>
                <Image src={this.props.content.image} caption={this.props.content.caption} />
            </section>
        )
    }
}

export default SingleImage
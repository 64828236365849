import React from 'react'

export const First = ( { onClick } ) => {
    return (
        <>
            <span className={ `_title _big _center` } >A Political Statement</span>
            <span className={ `_title _medium _center` } >per SPRINT 2020</span>
            <div onClick={ onClick } className={ `glass` } /> 
        </>
    )
}
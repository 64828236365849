import React from 'react'

class Attachments extends React.Component {
    render() {
        return (
            <section className={`Attachments m-width`}>
                <p className="_base _title">{this.props.content.title}</p>
                <p className={`_base _text`}>
                    {
                        this.props.content.attachments.map((attachment, index) => (
                            <React.Fragment key={index}>
                                <a target={`_blank`} href={attachment.link}>{attachment.label}</a><br/>
                            </React.Fragment>
                        ))
                    }
                </p>
            </section>
        )
    }
}

export default Attachments
import React from "react";
import "./Image.css";
// var imageAspectRatio = require("image-aspect-ratio");

class Image extends React.Component {
	// constructor (props) {
	//     super(props)
	//     this.image = React.createRef();
	//     this.container = React.createRef();

	//     this.state = {
	//         height: null,
	//         width: null,
	//         ratio: null,
	//     }
	// }

	// updateSize = () => {
	//     let srcWidth = this.container.current.parentElement.offsetWidth
	//     let srcHeight = this.container.current.parentElement.offsetWidth / (this.state.ratio)

	//     let dimensions = imageAspectRatio.calculate(srcWidth, srcHeight, this.container.current.parentElement.offsetWidth, window.innerHeight / 100 * 80)

	//     this.setState({
	//         height: dimensions.height,
	//         width: dimensions.width,
	//     })
	// }

	// onResize = () => {
	//     this.updateSize();
	// }

	// componentDidMount() {
	//     let width = this.props.src.width ? this.props.src.width : this.image.offsetWidth
	//     let height = this.props.src.height ? this.props.src.height : this.image.offsetHeight

	//     this.setState({
	//         ratio: width / height
	//     })

	//     this.updateSize();

	//     window.addEventListener('resize', this.onResize)
	// }

	// componentWillUnmount() {
	//     window.removeEventListener('resize', this.onResize)
	// }

	render() {
		return (
			<div ref={this.container} className={`Image`}>
				{this.props.src && (
					<img
						ref={this.image}
						// style={ {height: this.state.height, width: this.state.width} }
						onLoad={this.onImgLoad}
						src={this.props.src.sizes.large}
						alt={this.props.caption}
					/>
				)}

				{this.props.caption && (
					<div
						className={`_small _text`}
						dangerouslySetInnerHTML={{ __html: this.props.caption }}
					/>
				)}
			</div>
		);
	}
}

export default Image;
